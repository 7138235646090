import { action, makeObservable, observable } from 'mobx'
import { Vendor } from '../types/Vendor.type'

interface IVendorSearchModelProps {
  vendors: Vendor[]
  location: any
  category: any
}
class VendorsSearchModel {
  isSortModalOpen: boolean
  vendors: Vendor[]
  location: any
  category: any

  constructor ({ vendors, location, category }: IVendorSearchModelProps) {
    this.isSortModalOpen = false

    this.vendors = vendors
    this.location = location
    this.category = category

    makeObservable(this, {
      isSortModalOpen: observable,
      setIsSortModalOpen: action
    })
  }

  setIsSortModalOpen (isOpen: boolean) {
    this.isSortModalOpen = isOpen
  }
}

export default VendorsSearchModel
